.createButton {
  border-radius: 50%;
  background-color: var(--neutral-light);
  color: var(--success);
}

@media screen and (min-width: 480px) {
  .createButton {
    display: none;
  }
}
