/**/
.wrapper {
  position: sticky;
  top: 0;
  left: 0;

  width: 100%;
  height: 50px;
  padding: 10px 20px;

  z-index: 2;

  background-color: #ffffff;
  border-bottom: 1px solid var(--neutral-dark-5);
}

.wrapper > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
}

.menuIcon {
  float: left;

  margin-right: 10px;
}

.title {
  width: fit-content;
  min-width: 50%;
  line-height: 28px;
  font-weight: 600;

  text-overflow: ellipsis;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: fit-content;

  color: var(--neutral-dark-3);
}

.actions > * {
  margin-left: 5px;
}

.profileIcon {
  color: var(--blue-primary);
}

.wallet-balance-item {
  padding: 0;
}

.wallet {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid var(--neutral-dark-6);
  color: var(--success);
  padding: 10px;
  width: 100%;
}

.wallet > small {
  width: 100%;
  line-height: 10px;
  color: var(--neutral-dark-2);
}

.wallet > span {
  line-height: 10px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .actions > * {
    margin-left: 8.5px;
  }

  .menuIcon {
    display: none;
  }
}
