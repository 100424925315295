.rselect {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  color: var(--neutral-dark-3);
}

.rselect div {
  width: auto;
}

.rselect:hover,
.rselect:focus-within,
.rselect:focus {
  border-color: var(--blue-primary);
}

.control {
  border: 1px solid var(--neutral-dark-4);
  padding: 1px;
  width: 100% !important;
}

.option:hover {
  color: var(--neutral-light) !important;
  background-color: var(--blue-primary);
}
