@font-face {
  font-family: 'Solway';
  src: url('../font/Solway-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Solway';
  src: url('../font/Solway-Regular.ttf') format('truetype');
  font-weight: 200 400;
  font-style: normal;
}

@font-face {
  font-family: 'Solway';
  src: url('../font/Solway-Medium.ttf') format('truetype');
  font-weight: 500 600;
  font-style: normal;
}

@font-face {
  font-family: 'Solway';
  src: url('../font/Solway-Bold.ttf') format('truetype');
  font-weight: 700 900;
  font-style: normal;
}
