.greenBadge,
.infoBadge,
.dangerBadge {
  border-radius: 3px;
  padding: 3.5px 10px;
  font-size: 12px;
  color: var(--neutral-light);
}

.greenBadge {
  background: var(--success);
}

.infoBadge {
  background: var(--orange-primary);
}

.dangerBadge {
  background: var(--danger);
}

.editorWrapper {
  width: 100%;
  height: 100%;

  padding: 25px 20px;
}

.titleBar {
  border: 1px solid var(--neutral-dark-5);
  background-color: var(--neutral-light);
  border-radius: 10px;
  padding: 15px 25px;
}

.titleRow,
.btnWrap, .criterionInput {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
}

.description {
  grid-column: 2/5;
}

.btnWrap {
  margin-top: 5px;
}

.saveBtn {
  grid-column: 4;
}

.criteriaWrap {
  padding: 20px;
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 10px;
  margin-top: 20px;
}

.envToggle {
  text-align: right;
  line-height: 36px;
  margin-bottom: 10px;
}

.envToggle > select {
  float: right;
  padding: 5px 25px;
  outline: none;
  border: 1px solid var(--neutral-dark-5);
  background-color: var(--neutral-light);
}

.criterionInput {
  margin-bottom: 20px;
}

.addCriterionBtn {
  width: fit-content;
}