.gm-datatable {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
}

.gm-datatable-header,
.gm-datatable-table {
  padding: 10px 0;
  width: 100%;
}

.gm-datatable-header {
  position: sticky;
  float: left;

  padding: 60px 15px 10px 15px;

  background-color: var(--neutral-dark-6);
  border-bottom: 1px solid var(--neutral-dark-5);
}

.search-input {
  margin-bottom: 0;
  background-color: var(--neutral-light);
}

.gm-datatable-table {
  float: left;
  position: relative;
}

/**/
.gm-datatable-loader {
  width: 100%;
  height: 70vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gm-datatable-search-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 20px 0;
}

.gm-datatable-item-list-wrapper {
  width: 100%;
  height: 85vh;

  padding: 0 20px 45px 20px;
  overflow-y: scroll;
}

.gm-datatable-bulk-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.gm-datatable-bulk-checkbox input[type='checkbox'] {
  width: 100%;
  height: 100%;
  padding: 0;
}

.gm-datatable-actions {
  width: fit-content;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 30px 2.5px 30px;
}

.gm-datatable-actions-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3.5px;
}

.gm-datatable-actions-wrapper > div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.gm-datatable-actions-wrapper .close-action {
  font-size: 1.25em;
}

.gm-datatable-metadata {
  width: 50%;
  padding: 0;
  color: #666666;
}

.mobi-no-items {
  text-align: center;
  color: #666666;
}

.gm-datatable-foot-loader {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
}

/**/
@media screen and (min-width: 768px) {
  .gm-datatable {
    position: unset;
  }

  .gm-datatable > section.gm-datatable-header {
    background: var(--neutral-light);
    border: 1px solid var(--neutral-dark-5);
    border-radius: 5px;
  }

  .gm-datatable-header {
    position: unset;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;

    background: unset;

    width: 100%;
    padding: 10px;
  }

  .gm-header-position {
    justify-content: flex-end;
  }

  .gm-datatable-actions {
    border: none;
    padding: 0;

    display: flex;
    justify-content: space-between;
  }

  .gm-datatable-actions button {
    margin-left: 10px;
  }

  .gm-datatable-table {
    padding: 0;
    margin: 10px 0;
    border: 1px solid var(--neutral-dark-5);
  }

  .gm-datatable-row-selector-wrap > div {
    width: fit-content;
    padding: 5px 0;
  }

  .gm-datatable-row-selector-wrap > div.gm-datatable-row-selector {
    margin: 0 10px;
    padding: 0;
  }

  .gm-datatable-row-selector select {
    border-radius: 5px;
    padding: 5px;

    background: none;
  }

  .gm-datatable-search {
    width: 300px;
  }

  .gm-datatable-search > input {
    background-color: #fff;
    height: 100%;
  }

  /**/

  .gm-datatable thead {
    border-bottom: 1px solid var(--neutral-dark-6);
    background-color: var(--neutral-dark-7);
    color: var(--blue-dark);
  }

  .gm-datatable thead tr:hover {
    background: none;
    border: none;
  }

  .gm-datatable th {
    font-size: 12px;
    text-transform: uppercase;
  }

  .gm-datatable th,
  .gm-datatable td {
    padding: 10px 7.5px;
    word-wrap: break-word;
    line-break: strict;
  }

  .gm-datatable > section.gm-datatable-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    padding-top: 25px;
  }

  .gm-datatable-footer > div {
    width: auto;
  }

  .gm-datatable-loader-indicator {
    display: flex;
  }

  .gm-datatable-loader-indicator > span {
    padding: 0 15px 0 8px;
  }

  .gm-datatable-pagination {
    float: right;

    display: flex;
    flex-direction: row;
  }

  .gm-datatable-pagination button {
    outline: none;
    border: 1px solid var(--neutral-dark-3);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 10px;

    background: var(--neutral-dark-6);
    color: var(--neutral-dark-3);
    font-size: 14px;
  }

  .gm-datatable-pagination button:first-child {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
  }

  .gm-datatable-pagination button:hover {
    background-color: var(--neutral-dark-5);
  }
}
