.sliding-nav {
  border-bottom: 1px solid var(--neutral-dark-5);
  overflow-x: scroll;
}

.sliding-nav::-webkit-scrollbar {
  height: 0;
}

.sliding-nav a {
  display: inline-block;
  padding: 0 20px;
  line-height: 40px;
  text-decoration: none;

  color: #333333;
}

.sliding-nav > span > a.active,
.sliding-nav > span > a:hover {
  border-bottom: 3px solid var(--blue-primary);
  color: #19415e;
}

@media screen and (min-width: 1280px) {
  .sliding-nav {
    padding: 0 50px;
  }
  .sliding-nav a {
    display: inline-block;
    padding: 0 20px;
    /* line-height: 40px; */
    text-decoration: none;

    color: #333333;
  }
}
