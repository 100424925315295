/**/
.sideNavWrapper {
  position: fixed;
  top: 0;
  left: -100%;

  height: 100%;
  width: 100%;

  z-index: 99;
}

.link:hover {
  text-decoration: none;
}

.appSideNav {
  height: 100%;
  width: 75%;

  display: flex;
  flex-direction: column;

  background-color: #ffffff;
}

.appSideNav header {
  display: flex;
  flex-direction: row;
  align-content: center;

  height: 50px;
  width: 100%;

  background-color: #ffffff;
  padding: 10px;
}

.siteIcon {
  height: fit-content;
  align-self: center;
}

.siteName {
  color: var(--blue-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
}

.appSideNav section {
  width: 100%;
  padding: 5px 0;
  margin-top: 15px;
}

@media screen and (min-width: 768px) {
  .sideNavWrapper {
    float: left;
    position: unset;
    width: 10%;

    margin: 0;
  }

  .appSideNav {
    width: 100%;
    border-right: 1px solid var(--neutral-dark-5);
  }

  .appSideNav header {
    padding-left: 11.5px;
  }

  .siteName {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .sideNavWrapper {
    width: 15%;
  }

  .appSideNav header {
    padding: 15px;
  }

  .siteName {
    display: unset;
    line-height: 24px;
  }
}
