.envsPage {
  width: 100%;
  padding-top: 15px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 50px;
}

.envList,
.envForm {
  width: 100%;
}

.envList {
  height: fit-content;
  border: 1px solid var(--neutral-dark-5);
}

.envItem {
  padding: 15px;
  border-bottom: 1px solid var(--neutral-dark-5);
  background-color: var(--neutral-light);
}

.itemHeader {
  display: flex;
  justify-content: space-between;
}

.edit {
  margin-right: 10px;
  cursor: pointer;
}

.delete {
  color: var(--danger);
  cursor: pointer;
}

.envItem:last-child {
  border-bottom: none;
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
}

.envForm {
  padding: 0;
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  margin-bottom: 20px;
}

.envForm>header {
  font-size: 18px;
  font-weight: bold;

  border-bottom: 1px solid var(--neutral-dark-5);
}

.envForm>header,
.envForm>section {
  padding: 15px 20px;
}

.button {
  margin: 20px 0px 10px 0px;
}

.apiKeyWrapper {
  grid-column: 1/3;
  color: var(--neutral-dark-2);
}

.copyIcon {
  cursor: pointer;
}

.apiKeyWrapper>div {
  border: 1px solid var(--orange-primary);
  border-radius: 5px;
  line-height: 36px;
  min-height: 40px;
  padding: 2px 10px;
  overflow-x: hidden;
}