/**/
.dialog-veil {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.25);
  z-index: 999;
}

.dialog-content-wrapper {
  padding: 15px;
  width: 280px;

  background-color: #ffffff;

  text-align: center;
}

.dialog-message {
  font-size: 1.05rem;
}

.dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
}

.dialog-actions > button:first-child {
  margin-right: 10px;
}
