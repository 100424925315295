/**/
.modal {
  position: fixed;
  top: 0;
  left: 0;

  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  padding: 0 20px;

  background: rgba(0, 0, 0, 0);
}
.body::-webkit-scrollbar {
  width: 0;
}

.body {
  width: 90%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  overflow-y: auto;
  overflow: visible;

  display: flex;
  flex-direction: column;
}

.header {
  float: left;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 15px;
}

.title {
  width: auto;
  float: left;
}

.title h5 {
  font-size: 1.25rem;
}

.closeIcon {
  position: relative;
  top: -10px;
  width: auto;
  float: right;

  font-size: 25px;
  color: var(--neutral-dark-2);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal {
    padding: 0 50px;
    z-index: 9999;
  }

  .body {
    width: auto;
    max-width: 70%;
  }
}
