.wrapper {
  height: 100%;
  background-color: var(--neutral-light);
}

.content {
  padding: 10px 20px;
}

.wrapper > header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  background-color: var(--neutral-light);
}

@media screen and (min-width: 1280px) {
  .content {
    width: 100%;
    height: 100%;
    padding: 10px 50px 10px 50px;
    background-color: var(--neutral-dark-6);
  }
}
